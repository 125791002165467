<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";

defineProps<{
  src: string;
  alt?: string;
}>();
const emit = defineEmits<{
  adultClick: [];
}>();

const onClick = () => emit("adultClick");
</script>

<template>
  <div class="adult-image" @click="onClick">
    <div class="adult-image__bg"></div>
    <img class="adult-image__img" :src="src" :alt="alt" />
    <div class="adult-image__label">
      <VText class="adult-image__title" color="secondary"> товар 18+ </VText>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.adult-image {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__bg {
    padding: var(--pl-unit-x5);
    position: absolute;
    z-index: 1;
    background: rgb(18 18 18 / 8%);
    border-radius: var(--pl-unit-x4);
    width: 100%;
    height: 100%;
  }

  &__img {
    filter: blur(30px);
    z-index: 0;
    width: calc(95% - 80px) !important;
    height: calc(95% - 80px) !important;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    margin: var(--pl-unit-x1);
    position: absolute;
    text-align: center;
    z-index: 2;
    border-radius: var(--pl-unit-x4);
    padding: 0 6px;
    background: var(--pl-surface-primary-default);
    max-width: 100%;
    height: 22px;

    @media (--pl-viewport-from-l) {
      padding: 0 var(--pl-unit-x2) 0 6px;
      height: 28px;
    }
  }
}
</style>
